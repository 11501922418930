// Breakpoints
$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
  }
}
}
.SAQ_wrapper {
  width: 95% !important;
  margin: 2vh auto 0 !important;
  padding: 3vh 2vh !important;
  border-top: 120px solid #e4efff !important;
  background-color: #fff !important;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @include respond-to(sm) {
    width: 90% !important;
    padding: 4vh 3vh !important;
    border-top: 140px solid #e4efff !important;
    border-radius: 20px !important;
  }

  @include respond-to(md) {
    width: 80% !important;
    padding: 6vh !important;
    margin: 4vh auto 12vh !important;
    border-radius: 30px !important;
  }

  &__time {
    padding-top: 1rem !important;
    
    @include respond-to(md) {
      padding-top: 2rem !important;
    }
  }

  &__guide {
    color: #242222 !important;
    align-items:  center !important;
    justify-content: center !important;
    display: flex !important;
    
    
  }

  &__Question-box {
    z-index: 7;
    position: relative;
    top: -6vh;
    width: 90%;
    padding: 2vh 3vh;
    background-color: #fff;
    box-shadow: $smooth-shadow;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include respond-to(sm) {
      width: 77% !important;
      padding: 3vh;
      top: -7vh;
      border-radius: 12px !important;
    }

    @include respond-to(md) {
      width: 60% !important;
      padding: 3vh 8vh;
      top: -8vh;
      border-radius: 15px !important;
    }

    &--number {
      position: relative;
      top: -4vh;
      border-radius: 20px;
      background-color: $secondary-app-color !important;

      @include respond-to(md) {
        top: -6vh;
        border-radius: 30px;
      }

      &-text {
        padding: 1vh 4vh !important;
        font-family: $Gilroy-bold !important;
        font-size: 1.2rem !important;
        color: #fff !important;

        @include respond-to(sm) {
          padding: 1.2vh 5vh !important;
          font-size: 1rem !important;
        }

        @include respond-to(md) {
          padding: 1.2vh 5.5vh !important;
          font-size: 1.05rem !important;
        }
      }
    }

    &--question {
      font-family: $default-font-family !important;
      font-size: 1.4rem !important;
      color: $secondary-app-color !important;

      @include respond-to(sm) {
        font-size: 1.1rem !important;
      }

      @include respond-to(md) {
        font-size: 1.2rem !important;
      }
    }
  }

  &__options-wrapper {
    margin-top: 16vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;

    @include respond-to(md) {
      margin-top: 25vh !important;
      gap: 2rem;
    }

    &--option-button {
      white-space: normal !important;
      height: auto !important;
      min-height: 120px !important;
      max-width: 100% !important;
      width: 100% !important;
      border: 1px solid rgba(43, 61, 79, 0.26) !important;
      border-radius: 10px !important;
      font-family: $default-font-family !important;
      font-size: 0.9rem !important;
      color: $secondary-app-color !important;
      text-transform: capitalize !important;
      padding: 1rem !important;
      margin: 0 !important;
      line-height: 1.4 !important;
      word-break: break-word !important;
      overflow-wrap: break-word !important;
      box-sizing: border-box !important;
      
      // Force text to stay within bounds
      > span {
        width: 100% !important;
        display: block !important;
        word-break: break-word !important;
      }

      @include respond-to(sm) {
        font-size: 1rem !important;
        padding: 1.2rem !important;
        border-radius: 12px !important;
        min-height: 130px !important;
      }

      @include respond-to(md) {
        font-size: 1.1rem !important;
        padding: 1.5rem !important;
        border-radius: 15px !important;
        min-height: 150px !important;
      }

      &--active {
        @extend .SAQ_wrapper__options-wrapper--option-button;
        background-color: #eaffff !important;
        border: 2px solid $primary-color !important;
      }
    }


    &--option-icon {
      &-g, &-r {
        white-space: nowrap !important;
        font-size: 3rem !important;
        border-radius: 50% !important;
        width: 4rem !important;
        height: 4rem !important;

        @include respond-to(sm) {
          font-size: 4rem !important;
          width: 5rem !important;
          height: 5rem !important;
        }

        @include respond-to(md) {
          font-size: 5.1rem !important;
          width: 6rem !important;
          height: 6rem !important;
        }
      }

      &-g {
        color: #242222 !important;
        border-color: $secondary-app-color !important;

        &--active {
          @extend .SAQ_wrapper__options-wrapper--option-icon-g;
          color: #fff !important;
          background-color: $primary-color !important;
        }
      }

      &-r {
        color: #242222 !important;
        border-color: red !important;

        &--active {
          @extend .SAQ_wrapper__options-wrapper--option-icon-r;
          color: #fff !important;
          background-color: red !important;
        }
      }
    }
  }

  &__answer-wrapper {
    position: relative;
    top: -18vh;
    width: 95%;
    margin-bottom: -80px !important;

    @include respond-to(sm) {
      width: 87%;
      top: -20vh;
      margin-bottom: -90px !important;
    }

    @include respond-to(md) {
      width: 80%;
      top: -24vh;
      margin-bottom: -110px !important;
    }
  }

  &-navigate {
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff !important;
    padding: 3vh 0.5vh !important;

    @include respond-to(md) {
      padding: 5vh 0.5vh !important;
    }

    &__button {
      font-size: 0.7rem !important;
      color: #3b5269 !important;
      padding: 0.7vh 3vh !important;
      font-family: $Gilroy-bold !important;
      border-radius: 20px !important;
      border-color: #2b3d4f !important;
      text-transform: capitalize !important;
      margin-bottom: 5px !important;

      @include respond-to(sm) {
        font-size: 0.75rem !important;
        padding: 0.7vh 5vh !important;
        border-radius: 25px !important;
      }

      @include respond-to(md) {
        font-size: 0.85rem !important;
        padding: 0.7vh 8vh !important;
        border-radius: 30px !important;
      }
    }

    &__progress {
      width: 100%;
      height: 5px !important;
      border-radius: 0 !important;
      background-color: #e91515 !important;

      @include respond-to(md) {
        height: 7px !important;
        width: 105%;
      }

      .progress-bar {
        background-color: $secondary-app-color !important;
      }
    }
  }

  &__emoji-button {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 50%;
    transition: transform 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    padding: 8px;
    cursor: pointer;

    @include respond-to(md) {
      padding: 10px;
    }

    &:hover {
      transform: scale(1.1);
      border-color: #ccc;
      background-color: rgba(0, 0, 0, 0.05);
    }

    &__selected {
      border-color: green;
      background-color: #4caf50;
      color: white;
      transform: scale(1.1);
      border-radius: 0;
      padding: 12px;
      box-shadow: 0 0 5px rgba(0, 128, 0, 0.5);

      @include respond-to(md) {
        padding: 15px;
      }
    }

    &__size {
      font-size: 2rem !important;
      
      @include respond-to(sm) {
        font-size: 2.5rem !important;
      }

      @include respond-to(md) {
        font-size: 3rem !important;
      }
    }
  }
}

// Breakpoint mixins
@mixin xs {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 376px) and (max-width: 767px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

.AFQ_wrapper {
  margin-top: 2vh !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  width: 95% !important;
  padding: 3vh !important;
  margin-left: auto;
  margin-right: auto;
  text-align: left !important;

  @include xs {
    width: 92% !important;
    padding: 2vh !important;
  }

  @include sm {
    width: 90% !important;
    padding: 4vh 3vh !important;
    border-radius: 25px !important;
  }

  @include md {
    width: 85% !important;
    margin-top: 4vh !important;
    margin-bottom: 12vh !important;
    padding: 6vh !important;
    border-radius: 30px !important;
  }

  @include lg {
    width: 80% !important;
  }

  &__time {
    padding-top: 1.5rem !important;
    text-align: left !important;

    @include md {
      padding-top: 2rem !important;
    }
  }

  &__options-wrapper {
    margin-top: 15vh !important;
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-direction: column;
    gap: 1.5rem;
    width: 100% !important;
    padding: 0 15px !important;

    @include xs {
      margin-top: 12vh !important;
      gap: 1rem;
    }

    @include sm {
      margin-top: 16vh !important;
      gap: 1.5rem;
    }

    @include md {
      margin-top: 25vh !important;
      gap: 2rem;
    }
  }

  &__answer-wrapper {
    width: 100% !important;
    margin-top: 20px !important;
    margin-bottom: 1.5px;
    text-align: left !important;

    @include xs {
      width: 95% !important;
    }

    @include sm {
      width: 90% !important;
      margin-top: 25px !important;
    }

    @include md {
      width: 100% !important;
      margin-top: 30px !important;
    }
  }

  &__long-question-wrapper {
    padding: 2vh 0vh !important;
    font-size: 16px !important;
    font-family: $Gilroy-light !important;
    color: black;
    line-height: 1.6;
    text-align: left !important;
    word-break: normal !important;
    overflow-wrap: break-word !important;
    white-space: normal !important;
    display: block !important;

    @include sm {
      font-size: 14px !important;
      line-height: 1.7;
      padding: 3vh 0vh !important;
    }

    @include md {
      font-size: 18px !important;
      line-height: 1.9;
      padding: 4vh 0vh !important;
    }

    p {
      text-align: left !important;
      margin-bottom: 1rem !important;
    }
  }

  &__Question-box-v1 {
    position: relative;
    top: 3vh;
    width: 100%;
    min-height: fit-content;
    padding: 2vh 1.5vh !important;
    background-color: #e4efff;
    box-shadow: $smooth-shadow;
    border-radius: 12px !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    text-align: left !important;
    overflow: hidden !important;
    
    @include xs {
      padding: 4vh 2vh 2vh !important;
      border-radius: 13px !important;
    }

    @include sm {
      padding: 6vh 2.5vh 3vh !important;
      margin-top: 0vh !important;
      border-radius: 14px !important;
    }

    @include md {
      top: 4vh;
      padding: 5vh 3vh 3vh !important;
      margin-top: 7vh !important;
      border-radius: 15px !important;
    }

    &--number {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 25px;
      background-color: $secondary-app-color !important;
      z-index: 1;

      @include md {
        top: 15px;
        border-radius: 30px;
      }

      &-text {
        padding: 1vh 4vh !important;
        font-family: $Gilroy-bold !important;
        font-size: 0.95rem !important;
        text-align: center !important;
        white-space: nowrap !important;
        color: #fff !important;

        @include xs {
          padding: 1vh 4.5vh !important;
        }

        @include sm {
          padding: 1.2vh 6.5vh !important;
          font-size: 1rem !important;
        }

        @include md {
          padding: 1.2vh 5.5vh !important;
          font-size: 1.05rem !important;
        }
      }
    }

    &--question {
      font-family: $default-font-family !important;
      font-size: 1.2rem !important;
      color: $secondary-app-color !important;
      padding: 4vh 1.5rem 1.5rem !important;
      text-align: center !important;
      width: 100% !important;
      word-wrap: break-word !important;
      overflow-wrap: break-word !important;
      hyphens: auto !important;
      max-width: 100% !important;
      margin-top: 1.1vh !important;

      @include xs {
        font-size: 0.95rem !important;
        padding: 4vh 1rem 1rem !important;
      }

      @include sm {
        font-size: 1rem !important;
        padding: 4vh 1.5rem 1.5rem !important;
      }

      @include md {
        font-size: 1.2rem !important;
        padding: 4vh 2rem 2rem !important;
      }
    }
.IC_wrapper {
  margin-top: 4vh !important;
  border-radius: 30px !important;
  width: 80% !important;
  padding-left: 6vh !important;
  padding-right: 6vh !important;
  padding-bottom: 6vh !important;
  margin-left: auto;
  margin-right: auto;

  @include sm {
    padding: 4vh 3vh !important;
    // border-top: 140px solid #e4efff !important;
  }

  @include md {
    margin-top: 4vh !important;
    margin-bottom: 12vh !important;
  }

  &__image-container {
    // Styling for the image container
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    
    // Ensures the image container is responsive
    width: 100%;
    height: 350px;
    @media (max-width: 768px) {
      height: 300px;
    }

    @media (max-width: 480px) {
      height: 200px;
    }
  }

  &__image {
    // Image styling
    max-width: 100%;
    height: 100%;
    
    // Make sure image adjusts to various screen sizes
    @media (max-width: 768px) {
      max-width: 100%;
    }

    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
}
}

.css-ttgsjq-MuiSlider-track{
  background-color: #242222 !important;
  border: 1px solid #242222 !important;
}
.css-188mx6n-MuiSlider-root{
  color: #2b3d4f !important;
}
.css-ltlhnc-MuiSlider-root{
  color: #3b5269 !important;
}
}