.header-title{
 @include md {
    font-size: 5rem;
  }

}





.enrolled-course-card {
  min-height: 330px !important;
  width: 85%;
  border-radius: 30px !important;
  display: flex;
  flex-direction: column;
  overflow: visible !important;
  position: relative;
  box-shadow: none !important;

  &:hover {
    transition: 0.3s;
    transform: translate(0, -10px);
    box-shadow: 1.9px 2px 1.6px -19px rgba(0, 0, 0, 0.001),
      4.6px 4.7px 4.7px -19px rgba(0, 0, 0, 0.006),
      8.6px 8.9px 10.2px -19px rgba(0, 0, 0, 0.014),
      15.4px 15.9px 20.3px -19px rgba(0, 0, 0, 0.023),
      28.8px 29.7px 41.1px -19px rgba(0, 0, 0, 0.036),
      69px 71px 102px -19px rgba(0, 0, 0, 0.06) !important;
  }

  @include sm {
    width: 100% !important;
    margin-left: auto;
  }
  &__wrapper {
    position: relative;
    border-radius: 30px;
    padding: 1.75vh 1.75vh 0vh 1.75vh;
  }
  &__card-media {
    background-color: #e0f7fa;
    object-fit: contain;
    border-radius: 30px;
  }
  &__title {
    position: absolute;
    top: 36px;
    left: 26px;
    color: $dark-grey !important;
    font-weight: 300 !important;
    font-size: 1rem !important;
    @include sm {
      font-size: 0.8rem !important;
    }
    @include md {
      font-size: 0.8rem !important;
    }
    @include lg {
      font-size: 0.9rem !important;
    }
  }
  &__course-name {
    padding-left: 0.4vh 1vh !important;
    font-family: $Gilroy-semi-bold !important;
    font-size: 1.3rem !important;
    color: #000 !important;

    @include sm {
      font-size: 1rem !important;
      margin-bottom: 3vh !important;
    }
    @include md {
      font-size: 1rem !important;
    }
  }
  &--with-progress {
    @include border-radius-top;
  }

  &--without-progress {
    border-radius: 30px !important;
  }
}

.enrolled-courses-main-title {
  font-family: $default-font-family !important;
  margin-bottom: 3vh !important;
  margin-top: 1vh !important;
  font-size: 1.7rem !important;
  font-weight: 200 !important;
  color: $secondary-app-color;
  &#avalaible {
    margin-top: 7vh !important;
  }
  @include xs {
    font-size: 1.2rem !important;
  }
  @include sm {
    font-size: 1.4rem !important;
    margin-top: -0.6rem !important;
  }
  @include md {
    font-size: 1.5rem !important;
  }
}

.enrolled-course-card-button {
  color: $secondary-app-color !important;
  margin-top: 1vh !important;
  font-weight: 200 !important;
  &__start-learning {
    margin-top: 1vh !important;
    text-transform: none !important;
    color: $secondary-app-color !important;
    font-size: 1rem !important;
    font-family: $default-font-family !important;
  }
  &__download-certificate-wrapper {
    display: flex;
    align-items: center;
    &--text {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      margin-top: 1vh !important;
      color: $secondary-app-color !important;
      text-transform: none !important;
      font-size: 1rem !important;
      font-family: $default-font-family !important;
    }
    &--icon {
      width: 10%;
    }
  }
}

.available-course-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 30px !important;
  overflow: hidden !important;
  position: relative;
  box-shadow: none !important;

  &:hover {
    transition: 0.3s;
    transform: translate(0, -10px);
    box-shadow: 1.9px 2px 1.6px -19px rgba(0, 0, 0, 0.001),
      4.6px 4.7px 4.7px -19px rgba(0, 0, 0, 0.006),
      8.6px 8.9px 10.2px -19px rgba(0, 0, 0, 0.014),
      15.4px 15.9px 20.3px -19px rgba(0, 0, 0, 0.023),
      28.8px 29.7px 41.1px -19px rgba(0, 0, 0, 0.036),
      69px 71px 102px -19px rgba(0, 0, 0, 0.06) !important;
  }

  @include sm {
    width: 100% !important;
  }
  &__media-container {
    position: relative;
    border-radius: 30px;
    padding: 1.75vh;
  }
  &__media {
    background-color: #e0f7fa;
    object-fit: contain;
    border-radius: 30px;
  }
  &__course-title {
    color: $dark-grey !important;
    position: absolute;
    top: 40px;
    left: 26px;
    font-weight: 300;
    font-size: 1rem !important;
  }
  &__card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    height: 100%;
    padding-top: 0.5vh !important;
  }
  &__course-name {
    font-family: $Gilroy-semi-bold !important;
    font-size: 1.3rem !important;
    @include sm {
      font-size: 1rem !important;
      margin-bottom: 1vh !important;
    }
    @include md {
      font-size: 1.05rem !important;
    }
  }
  &__info {
    margin-bottom: 0.9rem;
    display: flex;
    align-items: center;

    &__rating-icon {
      margin-right: 1.4vh;
      width: 5.5%;
      @include sm {
        width: 4% !important;
      }
      @include md {
        width: 7% !important;
        margin-right: 1.3vh !important;
      }
    }
    &__rating {
      font-family: $Gilroy-light !important;
      font-weight: 100 !important;
      font-size: 0.85rem !important;
      margin-right: 1.6vh;
      @include sm {
        font-size: 0.8rem !important;
        margin-right: 1vh !important;
      }
      @include md {
        font-size: 0.8rem !important;
        margin-right: 1vh !important;
      }
    }
    &__duration-icon {
      margin-left: 2vh !important;

      margin-right: 1.4vh !important;
      width: 5.5% !important;
      @include sm {
        width: 4% !important;
      }
      @include md {
        width: 7% !important;
        margin-right: 1.3vh !important;
      }
    }
    &__duration {
      font-family: $Gilroy-light !important;
      margin-right: 2vh !important;
      font-weight: 100 !important;
      font-size: 0.85rem !important;
      @include sm {
        font-size: 0.8rem !important;
        margin-right: 1vh !important;
      }
      @include md {
        font-size: 0.8rem !important;
        margin-right: 1vh !important;
      }
    }
  }
  &__description {
    font-size: 0.95rem !important;
    font-family: $Gilroy-light !important;
    margin-bottom: 1rem !important;
    font-weight: 200 !important;
    color: $secondary-app-color !important;
  }
  &__enroll-now-button {
    text-transform: none !important;
    font-family: $Gilroy-bold !important;
    padding: 5px 25px !important;
    font-size: 0.775rem !important;
    align-self: flex-start;
    text-transform: none;
    border-radius: 30px !important;
    color: $secondary-app-color !important;
    border-color: #2b3d4f !important;
    margin-bottom: 0.5vh !important;

    @include sm {
      font-size: 0.6rem !important;
    }
    @include md {
      font-size: 0.7rem !important;
    }

    &:hover {
      border-color: #2b3d4f !important;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
