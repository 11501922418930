.footer-wrapper {
  background-color: $secondary-app-color !important;
  width: 100%;
  padding: 1.5vh 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  @include sm {
    width: 100%;
    padding: 1.2vh 0 !important;
  }
  
  @include md {
    width: 100%;
    margin-top: auto !important;
  }
  
  &__text {
    font-size: 0.7rem !important;
    font-family: $Gilroy-regular !important;
    color: #dfdfdf !important;
    display: inline-block;
    
    @include sm {
      font-size: 0.7rem !important;
    }
  }
  
  &__link {
    text-decoration: underline !important;
    font-size: 0.8rem !important;
    font-family: $Gilroy-regular !important;
    color: #dfdfdf !important;
    
    @include sm {
      font-size: 0.7rem !important;
    }
  }
}