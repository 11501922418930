//dashboard page
 //.dashbord-page {
 // position: relative !important;
 //}

 .dashboard-card {
  padding: 1vh !important;
  width: 38%;
  border-radius: 35px !important;
  background-color: #e9f4ff !important;
  position: absolute;
  top: 120px;
  right: 43px;
  
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  box-shadow: none !important;
  
  @include sm {
    position: static;
    width: 97% !important;
    margin-top: 4vh;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
  @include md {
    width: 40% !important;
    position: absolute;
    top: 290px; 
    right: 30px;
    height: auto;
    margin: 0 !important;
    z-index: 10; // Ensure it's above other elements
  }

  &__button {
    text-transform: none !important;
    width: 45% !important;
    padding: 1.5vh 2.5vh !important;
    font-family: $Gilroy-bold !important;
    font-size: 0.8rem !important;
    border-radius: 30px !important;
    background-color: $secondary-app-color !important;
    color: white !important;
    box-shadow: none !important;
    @include sm {
      width: 49% !important;
      font-size: 0.6rem !important;
      margin-bottom: 3.5vh !important;
    }
    @include md {
      width: 60% !important;
      font-size: 0.65rem !important;
      white-space: nowrap;
      padding: 1vh 2vh !important;
      margin-bottom: 2vh !important;
    }
  }
}

.dashboard-card-content {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 5vh !important;
  @include sm {
    padding: 1vh !important;
    position: relative;
  }
  @include md {
    padding: 2vh !important;
  }
  &__title {
    font-family: $Gilroy-bold !important;
    color: black !important;
    font-size: 1.7rem !important;
    margin-top: 2vh !important;
    margin-bottom: 0 !important;
    @include sm {
      font-size: 1.45rem !important;
      margin-top: 3vh !important;
    }
    @include md {
      font-size: 1.37rem !important;
      margin-top: 1vh !important;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    padding-top: 5px;
    @include md {
      padding-top: 0.5vh !important;
    }
  }
  &__text-icon-wrapper {
    display: flex;
    align-items: center;
    @include sm {
      margin-right: 1vh !important;
    }
    @include md {
      padding-top: 0 !important;
      margin-right: 0.5vh !important;
    }
  }
  &__icon {
    width: 16%;
    margin-right: 1.3vh !important;
    &#star {
      width: 30%;
      @include sm {
        width: 34% !important;
      }
      @include md {
        width: 33% !important;
        margin-right: 1.2vh !important;
      }
    }
    &#clock {
      width: 22%;
      @include sm {
        width: 21% !important;
      }
      @include md {
        width: 20% !important;
      }
    }
    @include sm {
      width: 13% !important;
    }
    @include md {
      width: 16%;
      margin-right: 1vh !important;
    }
  }
  &__text {
    font-family: $Gilroy-light !important;
    font-size: 1.1rem;
    @include sm {
      font-size: 0.89rem !important;
    }
    @include md {
      font-size: 0.81rem !important;
      white-space: nowrap;
    }
    @include lg {
      font-size: 1rem !important;
    }
  }
  &__description {
    font-family: $Gilroy-light !important;
    color: #3b5269 !important;
    font-size: 1rem !important;
    letter-spacing: 0.11em !important;
    margin-bottom: 0 !important;
    padding-bottom: 2vh !important;
    @include sm {
      margin-top: 0 !important;
      font-size: 0.75rem !important;
    }
    @include md {
      margin-top: 5px !important;
      font-size: 0.8rem !important;
      padding-bottom: 0 !important;
    }
  }
  &__download {
    width: 75%;
    max-width: 100%;
    padding: 1vh;
    align-self: flex-start;
    margin-bottom: 2vh !important;
    margin-top: 2vh !important;
    border-radius: 30px !important;
    box-shadow: none !important;
    @include sm {
      margin-top: 1vh !important;
      width: 86% !important;
      margin-bottom: 0 !important;
    }
    @include md {
      margin-top: 1vh !important;
      width: 100% !important;
      padding: 0vh;
    }
  }
  &__download-content {
    padding-top: 1vh !important;
    padding-bottom: 1vh !important;
    display: flex;
    align-items: center;
    &:last-child {
      padding-bottom: 12px;
    }
    @include sm {
      width: 10% !important;
    }
  }
  &__download-image-border {
    padding: 1.2vh !important;
    margin-right: 2.5vh !important;
    background-color: #f4f9ff !important;
    border-radius: 15px;
    @include sm {
      padding: 0.3vh !important;
    }
    @include md {
      margin-right: 0.9vh !important;
      padding: 0.5vh !important;
    }
  }
  &__download-content-main-text {
    color: black !important;
    font-family: $Gilroy-semi-bold !important;
    font-size: 1.2rem !important;
    @include sm {
      font-size: 0.8rem !important;
      white-space: nowrap;
    }
    @include md {
      font-size: 0.9rem !important;
      white-space: nowrap;
    }
  }
  &__download-content-text-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1vh !important;
    @include md {
      margin-top: 0.5vh !important;
    }
  }
  &__download-content-yellow-clock {
    width: 9% !important;
    margin-right: 1vh !important;
    @include sm {
      width: 11% !important;
    }
    @include md {
      width: 7% !important;
    }
  }
  &__download-content-text {
    font-family: $Gilroy-light !important;
    font-size: 0.9rem !important;
    color: black !important;
    @include sm {
      font-size: 0.75rem !important;
    }
    @include md {
      font-size: 0.84rem !important;
    }
  }
  &__download-content-chevron-right {
    width: 65% !important;
    @include sm {
      width: 60% !important;
    }
    @include md {
      width: 54% !important;
    }
    &:hover {
      background-color: #fff;
    }
  }
  &__download-content--iconButton {
    &:hover {
      border-radius: 50% !important;
      background-color: #fff !important;
    }
  }
}

//recommended course
// .recommended-course {
//   @include md {
//     display: flex;
//     flex-direction: column;
//   }
// }
