//input field -input text
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  color: #667085 !important;
  font-family: $Gilroy-regular !important;
}

.MuiInput-input {
  color: #667085 !important;
  font-family: $Gilroy-regular !important;
}
//input field -password
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  color: #667085 !important;
  font-family: $Gilroy-regular !important;
}

// auth layout conatiner
.auth-layout__container {
  min-height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @include sm {
    overflow: visible !important;
  }
}

.auth-layout__container--login {
  background: url("../../assets/images/group1.svg") center center / cover
    no-repeat;
}

.auth-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 45%;
  height: 100%;
  margin: 4.6vh;
  padding-top: 4.6vh;
  padding-bottom: 4.6vh;
  @include sm {
    height: 90%;
  }
}
.create-account-wrapper {
  width: 50%;
  @include sm {
    height: auto;
  }
  @include lg {
    width: 86% !important;
  }
}
// .create-account-form {
//   @include lg {
//     width: 80% !important;
//   }
// }
.auth-form-card {
  background-color: white;
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  box-shadow: $smooth-shadow;
  // margin: 100px;
  margin: 6vh 2vh 6vh 2vh;
  width: 73%;
  height: 100%;
  padding: 1vh 2vh 1vh 2vh;
  // @include sm {
  //   margin: 10vh 2vh 16vh 2vh;
  // }
  &#login {
    @include sm {
      margin: 3vh 2vh 12vh 2vh !important;
      padding: 29vh 2vh 1vh 2vh !important;
      height: auto;
    }
    @include md {
      margin: 6vh 2vh 8vh 2vh !important;
    }
  }
}
.create-account-card {
  background-color: white;
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  box-shadow: $smooth-shadow;
  margin: 60px !important;
  width: 80%;
  height: 100%;
  &__form {
    margin-top: 0 !important;
    width: 442px !important;
    height: 463px !important;
  }
}
.login-card-logo {
  margin-bottom: 1vh;
  width: 115px;
  height: 115px;
  margin-top: 5px;
  padding: 1vh;
}

.login-form {
  width: 340px;
  height: 463px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forgot-pwd_form {
  width: 85% !important;
  @include md {
    width: 79% !important;
  }
}
.forget-pwd_form-wrapper {
  width: 360px;
  height: 463px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forgot-pwd__form-card {
  background-color: white;
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  box-shadow: $smooth-shadow;
  margin: 100px;
  width: 77%;
  height: 60%;
  padding: 10px;

  &#reset-password {
    height: 69%;
    @include md {
      width: 60%;
      height: 59%;
    }
  }

  @include sm {
    box-shadow: none !important;
    border-radius: 0;
    width: 100%;
    height: 100%;
    
  }
  @include md {
    width: 60%;
    height: 73%;
  }
}
.forgot-pwd__card-logo {
  width: 115px;
  height: auto; // Ensures the height adjusts proportionally
  margin-top: 5vh !important;

  @include sm {
    width: 100px;
    height: auto; // Keeps the aspect ratio intact for smaller screens
  }
  @include md {
    width: 100px;
    height: auto; // Keeps the aspect ratio intact for medium screens
  }
}

.forgot-pwd__header-text {
  margin-top: 4vh !important;
  color: #101828 !important;
  font-size: 2.25rem !important;
  font-weight: 400 !important;
  @include sm {
    font-size: 1.8rem !important;
  }
  @include md {
    font-size: 1.7rem !important;
  }

 
  
}
.forgot-pwd__header-caption {
  color: #475467 !important;
  color: #475467 !important;
  margin-top: 1vh !important;
  margin-bottom: 3vh !important;
  font-size: 0.95rem !important;
  font-weight: 100 !important;
  @include sm {
    font-size: 0.8rem !important;
  }
  @include md {
    font-size: 0.85rem !important;
  }
}
.forgot-pwd__input-label {
  color: #344054 !important;
  margin-bottom: 10px !important;
  font-size: 0.875rem !important;
  
  &--confirm-password {
    margin-top: 1.5vh !important;
    margin-bottom: 1.5vh !important;
    font-size: 0.875rem !important;
    color: #344054 !important;
  }
}
.button-submit {
  border-radius: 30px !important;
  font-size: 1rem !important;
  box-shadow: none !important;
  text-transform: none !important;
  font-family: $Gilroy-bold !important;
  &#reset-password-submit {
    margin-top: 1vh !important;
  }
  @include sm {
    font-size: 0.7rem !important;
  }
  @include md {
    font-size: 0.8rem !important;
  }
}

.text {
  font-family: $default-font-family !important;
}
.text-semi-bold {
  font-family: $Gilroy-semi-bold !important;
}
.my-true-skill {
  color: #475467 !important;
  font-family: $Gilroy-regular !important;
  margin-bottom: 15px;
  text-decoration: underline !important;
}
.forgot-password {
  display: flex;
  align-items: center;
  gap: 0rem;
  margin-bottom: 2.5vh !important;
  font-family: 'Gilroy Medium' !important;
  font-size: 0.875rem !important;
  font-weight: 800 !important;
  color: #475467 !important;

  @include xs {
    font-size: 0.6rem !important;
  }
  @include sm {
    font-size: 0.75rem !important;
  }
  @include md {
    font-size: 0.8rem !important;
  }

  &__icon {
    margin-top: 0.6vh !important;

    @include sm {
      margin-top: 0.65vh !important;
      font-size: 1.4rem !important;
    }
  }

  a {
    font-family: $Gilroy-semi-bold !important;
    font-size: 0.875rem !important;
    color: #2b3d4f !important;

    &#login {
      margin-left: 0.5vh;
    }
  }
}

.header-text {
  color: #101828 !important;
  font-size: 2.25rem !important;
  margin-top: 2vh !important;
  font-weight: 400 !important;
}
.header-text-create-account {
  font-size: 2.2rem !important;
}

.header-caption {
  color: #475467 !important;
  margin-top: 1vh !important;
  margin-bottom: 2.5vh !important;
  font-size: 1rem !important;
  font-weight: 100 !important;
}

.input-field-label {
  font-family: $default-font-family !important;
  color: #344054 !important;
  font-weight: 200 !important;
}
.input-field-label-create-account {
  color: #344054 !important;
  font-family: $default-font-family !important;
  font-weight: 200 !important;
  font-size: 0.9rem !important;
}
.input-field-label-auth {
  font-size: 0.875rem !important;
  margin-bottom: 10px !important;
}

a {
  text-decoration: none !important;
  font-weight: 500 !important;
}

.button {
  border-radius: 10px;
  background-color: #39cccc !important;
}
.button-signup {
  border-radius: 30px !important;
  text-transform: none !important;
  font-size: 1rem !important;
  font-family: $Gilroy-bold !important;
  margin-top: 0.5vh !important;
  margin-bottom: 1.5vh !important;
  box-shadow: none !important;
}
.button-create-account {
  border-radius: 30px !important;
  font-size: 1rem !important;
  box-shadow: none !important;
  text-transform: none !important;
  font-family: $Gilroy-bold !important;
  margin-top: 6vh !important;
  margin-bottom: 0.4vh !important;
}

.create-account {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 2.5vh !important;
  font-family: $Gilroy-regular !important;
  color: #475467 !important;
  font-weight: 200 !important;
  font-size: 0.875rem !important;

  &__icon {
    margin-top: 0.6vh !important;
    @include sm {
      margin-top: 0.65vh !important;
      font-size: 1.4rem !important;
    }
  }

  a {
    font-family: $Gilroy-semi-bold !important;
    font-size: 0.875rem !important;
    color: #2b3d4f !important;
    &#login {
      margin-left: 0.5vh;
    }
  }
}


//smartphone responsiveness

//for login form

//-----------------------------for xs screen

.auth-layout__container--login {
  @include xs {
    background: none !important;
    padding: 0% !important;
  }
}

.auth-form-wrapper {
  @include xs {
    width: 100% !important;
  }
}

.auth-form-card {
  @include xs {
    width: 90% !important;
    box-shadow: none;
  }
}

.create-account-card {
  @include xs {
    box-shadow: none;
    width: 90%;
  }
}

.login-card-logo {
  @include xs {
    width: 33% !important;
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;
  }
}



.header-text {
  @include xs {
    font-size: 1.5rem !important;
  }
}

.header-caption {
  @include xs {
    font-size: 0.8rem !important;
  }
}

.input-field-label {
  @include xs {
    font-size: 0.6rem !important;
  }
}

.create-account {
  @include xs {
    font-size: 0.7rem !important;
  }
}

.button-signup {
  @include xs {
    font-size: 0.5rem !important;
  }
}

.my-true-skill {
  @include xs {
    font-size: 0.6rem !important;
  }
}
.footer-text {
  font-family: $Gilroy-regular !important;
  font-size: 0.875rem !important;
  color: #475467 !important;
  @include xs {
    font-size: 0.6rem !important;
  }
}

//-----------------------------------for sm screen size

.auth-layout__container--login {
  @include sm {
    background: none !important;
  }
}
.auth-form-wrapper {
  @include sm {
    margin: auto;
    width: 100%;
  }
}
.auth-form-card {
  @include sm {
    width: 98% !important;
    box-shadow: none;
  }
}
.login-card-logo {
  @include sm {
    margin-top: 2vh;
    width: 33%;
    height: 30%;
    padding: 0;
    margin-top: 2vh;
  }
}
.header-text {
  @include sm {
    margin-bottom: 2vh !important;
    font-size: 2.6rem !important;
    margin-top: 4vh !important;
  }
}

.header-caption {
  @include sm {
    font-size: 0.9rem !important;
  }
}

.input-field-label {
  @include sm {
    font-size: 0.75rem !important;
  }
}
.button-signup {
  @include sm {
    margin-bottom: 2vh !important;
    font-size: 0.8rem !important;
  }
}


.create-account {
  @include sm {
    font-size: rem !important;
  }
}
.footer-text {
  @include sm {
    font-size: 0.7rem !important;
  }
}
//-----------------------------for md screen
.auth-layout__container--login {
  @include md {
    background: none;
  }
}
.auth-form-wrapper {
  @include md {
    margin: auto;
    width: 100%;
  }
}

.auth-form-card {
  @include md {
    width: 100% !important;
    box-shadow: none;
  }
}

.login-form {
  @include md {
    width: 60%;
    height: 60%;
  }
}
.login-card-logo {
  @include md {
    margin-top: 3vh;
    width: 27%;
    height: 27%;
    padding: 0;
  }
}
.header-text {
  @include md {
    margin-top: 3vh !important;
    margin-bottom: 2vh !important;
    font-size: 2.4rem !important;
  }
}

.header-caption {
  @include md {
    font-size: 1rem;
  }
}

.input-field-label {
  @include md {
    font-size: 0.8rem !important;
  }
}

.button-signup {
  @include md {
    font-size: 0.8rem !important;
  }
}


.create-account {
  color: #475467 !important;
  // a {
  //   color: $secondary-app-color !important;
  // }
  @include md {
    font-size: 0.8rem !important;
  }
}

// for create account form

//--------------------------for xs screen

.create-account-card {
  @include xs {
    height: 100%;
  }
  @include lg {
    width: 64% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.header-text-create-account {
  @include xs {
    font-size: 1rem !important;
  }
}

.create-account-logo {
  width: 26% !important;
  margin-top: 1.5vh !important;
  padding: 0 !important;
  @include xs {
    margin-top: 35vh !important;
    width: 25% !important;
    height: 25% !important;
    margin-bottom: 1vh !important;
  }
}

.header-caption-create-account {
  margin-bottom: 4vh !important;
  @include xs {
    margin-top: 0 !important;
    font-size: 0.5rem !important;
  }
}

.button-create-account {
  @include xs {
    margin-bottom: 0%;
  }
}
// .input-field-label-create-account {
//   @include sm {
//     font-size: 0.7rem !important;
//   }
// }

//---------------------------for sm screen

.create-account-card {
  @include sm {
    height: 100%;
  }
}

.header-text-create-account {
  @include sm {
    font-size: 2.4rem !important;
  }
}

.create-account-logo {
  @include sm {
    margin-top: 32vh !important;
    width: 24% !important;
    height: 24% !important;
    margin-bottom: 1vh !important;
  }
}

.header-caption-create-account {
  @include sm {
    margin-top: 0 !important;
  }
}

.input-field-label-create-account {
  @include sm {
    font-size: 0.75rem !important;
  }
}

.button-create-account {
  @include sm {
    font-size: 0.8rem !important;
  }
}

.footer-text-create-account {
  font-size: 0.85rem !important;
  padding-bottom: 3vh !important;
  color: #475467 !important;
  font-family: $Gilroy-regular !important;
  @include sm {
    padding-bottom: 0vh !important;
    margin-top: 42vh !important;
    font-size: 0.75rem !important;
  }
}
//--------------------------for md screen
// Applies styles from 600px to 899px

.create-account-card {
  @include sm {
    height: 100%;
  }
}

.input-field-label-create-account {
  @include md {
    font-size: 0.8rem !important;
  }
}

.button-create-account {
  @include md {
    font-size: 0.8rem !important;
  }
}

// error message -login
.error-input {
  &_login {
    margin-top: 0.4vh !important;
    color: red !important;
    font-size: 0.8rem !important;
    font-family: $Gilroy-light !important;
  }
}

button:disabled {
  opacity: 0.35;
}

.create-account-public-layout{
  border-radius: 30px;
  background-color: #fff;
  padding: 6rem;
  margin: 4rem 2rem;
}