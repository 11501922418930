.quiz_result_wrapper {
    margin: 30px;

    .quiz-result-card {
        display: flex;
        align-items: center;
        padding-left: 5vh;
        padding-right: 5vh;
        padding-top: 6vh;
        padding-bottom: 6vh;
        background-color: #fff;
        width: 100%;

        &_quiz-name{
            font-family: $Gilroy-semi-bold !important;
            width:100% !important;

            @include sm{
                font-size: 1rem !important;
            }
        }

        &_border-green {
            border-left: 8px solid green;
        }

        &_border-grey {
            border-left: 8px solid $dark-grey;
        }

        &__subbox {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            margin-bottom: 2rem;

            &-button {
                border-radius: 16px !important;
                padding: 0.5rem 2.5rem !important;

                &--green {
                    background-color: $color-g !important;
                }

                &--grey {
                    background-color: $dark-grey !important;
                }
            }
        }
    }

    // Mobile (max-width: 600px)
    @media (max-width: 600px) {
        .quiz-result-card {
            padding-left: 2vh;
            padding-right: 2vh;
            padding-top: 3vh;
            padding-bottom: 3vh;

            &__subbox {
                flex-direction: column;
                margin-top: 1rem;
                margin-bottom: 1rem;

                &-button {
                    padding: 0.5rem 2rem !important;
                    width: 100%;
                }
            }
        }
    }

    // Tablet (max-width: 1024px)
    @media (max-width: 1024px) {
        .quiz-result-card {
            padding-left: 3vh;
            padding-right: 3vh;
            padding-top: 4vh;
            padding-bottom: 4vh;

            &__subbox {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;

                &-button {
                    padding: 0.5rem 2.5rem !important;
                }
            }
        }
    }

    // Desktop (min-width: 1025px)
    @media (min-width: 1025px) {
        .quiz-result-card {
            padding-left: 5vh;
            padding-right: 5vh;
            padding-top: 6vh;
            padding-bottom: 6vh;

            &__subbox {
                margin-top: 2rem;
                margin-bottom: 2rem;

                &-button {
                    padding: 0.5rem 2.5rem !important;
                }
            }
        }
    }
}

.quiz-view-all-modal{
    margin: 1.5rem 1.5rem !important; 
    outline: none !important; 
    border-radius: 8px !important; 
    background-color: #F5FAFF !important; 
    padding: 2rem !important;
    font-family: $Gilroy-semi-bold !important; 
    width: 100% !important;

    &__answer{
        border-radius: 0 !important; 
        border-left: 8px solid green !important; 
        background-color: white !important; 
        padding: 2rem !important;
    }
    
    &__feedback{
        border-radius: 0 !important; 
        background-color: #E2FFEA !important; 
        padding: 2rem !important;
    }
}

/* Fixed styles for the dialog header alignment */
.MuiDialogTitle-root {
    .MuiGrid-container {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        gap: 12px !important;
        
        .MuiGrid-item {
            display: flex !important;
            align-items: center !important;
            
            .quiz-result-card_quiz-name {
                margin: 0 !important;
                white-space: nowrap !important;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
            }
            
            .MuiBox-root {
                display: flex !important;
                align-items: center !important;
                gap: 8px !important;
                white-space: nowrap !important;
            }
        }
    }
}

/* Dialog header date-time display fixes */
.MuiDialogTitle-root .MuiGrid-container .MuiBox-root {
    min-width: 160px !important;
    justify-content: flex-start !important;
}

/* Calendar icon and date text alignment */
.MuiDialogTitle-root .MuiGrid-container .MuiBox-root svg {
    margin-right: 4px !important;
}

/* Fix for mobile view */
@media (max-width: 600px) {
    .MuiDialogTitle-root {
        padding: 16px 16px 8px 16px !important;
        
        .MuiGrid-container {
            flex-direction: column !important;
            align-items: flex-start !important;
            gap: 8px !important;
            
            .MuiGrid-item {
                width: 100% !important;
                
                .quiz-result-card_quiz-name {
                    font-size: 18px !important;
                    margin-bottom: 4px !important;
                }
                
                .MuiBox-root {
                    width: 100% !important;
                    justify-content: flex-start !important;
                    font-size: 14px !important;
                }
            }
        }
    }
}

.css-3su884-MuiPaper-root{
    box-shadow: none !important;
}