.course-price-wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  width: 70%;
  height: 75%;
  padding: 6vh 4vh;
  border-radius: 30px !important;
  box-shadow: none !important;
  @include sm {
    width: 80%;
  }
  @include md {
    padding: 4vh 2.5vh !important;
    width: 72%;
    height: 65%;


  }
  &__course-info {
    margin-bottom: 3vh !important;
    @include md {
      margin-bottom: 1vh !important;
    }
    &--title {
      margin-top: 2vh !important;
      font-size: 1.35rem !important;
      font-family: $Gilroy-semi-bold !important;
      margin-bottom: 0.5rem !important;
      @include sm {
        font-size: 1.3rem !important;
      }
      @include md {
        font-size: 0.9rem !important;
      }
    }
    &__details {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      @include sm {
        margin-top: 0.5vh !important;
        gap: 0.1rem;
      }
      @include md {
        margin-top: 0.6vh !important;
        gap: 0rem;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-icon {
        width: 10%;
        margin-right: 0.25rem;
      }

      #star {
        width: 31%;
        @include sm {
          margin-right: 0.4rem;
          width: 32% !important;
        }
        @include md {
          width: 27% !important;
          margin-right: 0.4rem;
        }
      }

      #barchart {
        width: 15%;
        @include sm {
          margin-right: 0.4rem;
          width: 14.5% !important;
        }
        @include md {
          width: 13% !important;
          margin-right: 0.4rem;
        }
      }

      #clock {
        width: 25%;
        @include sm {
          margin-right: 0.4rem;
          width: 23% !important;
        }
        @include md {
          width: 21% !important;
          margin-right: 0.4rem;
        }
      }

      &-text {
        margin-left: 0.1vh !important;
        font-family: $Gilroy-light !important;
        font-size: 0.8rem !important;
        color: #555;
        @include sm {
          margin-right: 0.4rem !important;
          margin-left: 0vh !important;
          font-size: 0.8rem !important;
          white-space: nowrap;
        }
        @include md {
          margin-right: 0vh !important;
          margin-left: 0vh !important;
          font-size: 0.57rem !important;
          white-space: nowrap;
        }
      }
    }
  }

  &__details-list {
    margin-top: 0.5vh !important;
    &--item {
    }
    &--title {
      padding: 1vh 0vh !important;
      font-size: 1.05rem !important;
      font-family: $default-font-family !important;
      color: $secondary-app-color !important;
      @include sm {
        font-size: 1.1rem !important;
      }
      @include md {
        font-size: 0.7rem !important;
      }
    }
    &--icon {
      font-size: 1.15rem !important;
      opacity: 0.89;
      @include sm {
        font-size: 1.1rem !important;
      }
      @include md {
        font-size: 0.75rem !important;
      }
    }
    &--text {
      font-size: 0.8rem !important;
        font-family: $Gilroy-light !important;
        color: #2b3d4f !important;
      .css-10hburv-MuiTypography-root {
        font-size: 0.8rem !important;
        font-family: $Gilroy-light !important;
        color: #2b3d4f !important;
        @include sm {
          font-size: 0.825rem !important;
        }
        @include md {
          font-size: 0.55rem !important;
        }
      }
    }
  }
  &__price-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.5vh !important;
    margin-bottom: 2vh !important;
    @include md {
      margin-top: 2vh !important;
      margin-bottom: 1.7vh !important;
    }
    &-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      @include md {
        gap: 0.5rem;
      }
    }

    &--discount-wrapper {
      background-color: rgba(57, 204, 204, 0.22) !important;
      border-radius: 15px !important;
      padding: 0.85vh 1.8vh;
      @include md {
        padding: 0.5vh 0.7vh;
      }
      &--discount {
        font-size: 0.75rem !important;
        font-family: $Gilroy-semi-bold !important;
        color: #39cccc !important;
        text-transform: uppercase !important;
        @include sm {
          font-size: 0.8rem !important;
        }
        @include md {
          font-size: 0.5rem !important;
        }
      }
    }
    &--price-now {
      font-size: 1.55rem !important;
      font-family: $Gilroy-semi-bold !important;
      color: $secondary-app-color !important;
      @include sm {
        font-size: 1.5rem !important;
      }
      @include md {
        font-size: 1.2rem !important;
      }
    }
    &--price-before {
      text-decoration: line-through !important;
      font-size: 1.05rem !important;
      opacity: 0.33;
      font-family: $Gilroy-regular !important;
      color: #2b3d4f !important;
      @include md {
        font-size: 0.8rem !important;
      }
    }
  }
  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--buy-now {
      text-transform: none !important;
      padding: 0.75vh 0vh !important;
      border-radius: 30px !important;
      border-color: #2b3d4f !important;
      box-shadow: none !important;
      font-family: $Gilroy-bold !important;
      font-size: 0.8rem !important;
      color: #3b5269 !important;
      margin-bottom: 1.8vh !important;
      @include sm {
        font-size: 0.85rem !important;
      }
      @include md {
        padding: 0.3vh 0vh !important;
        font-size: 0.59rem !important;
        margin-bottom: 1vh !important;
      }
    }
    &--apply-coupon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100% !important;
      text-transform: none !important;
      font-family: $default-font-family !important;
      color: #475467 !important;
      font-size: 0.75rem !important;
      margin-bottom: 1vh !important;
      @include sm {
        font-size: 0.73rem !important;
      }
      @include md {
        font-size: 0.49rem !important;
      }
    }
  }
}

.apply-coupon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f5fef9;
  border-radius: 15px;
  padding: 0.5vh 1.5vh;
  width: 100%;
  border: 1px solid #a3e8c3 !important;
  &_wrapper {
    display: flex;
    align-items: center;
    &--check-icon {
      color: #1f9c61 !important;
      margin-right: 1vh;
      font-size: 1.2rem !important;
    }
    &--text {
      font-size: 0.8rem !important;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      color: #318e66 !important;
      font-family: $default-font-family !important;
    }
    &--coupon-code {
      font-size: 0.8rem !important;
      font-family: $Gilroy-bold !important;
      color: #10794c !important;
    }
    &--x-icon {
      font-size: 1.2rem !important;
      color: #3bbc7d !important ;
    }
  }
}

.auth-modal-container {
  width: 100%;
  min-height: 100vh; 
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
