@mixin for-tablet {
  @media (min-width: 600px) and (max-width: 900px) {
    @content;
  }
}

.circular-progress-wrapper {
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center;


  @include for-tablet {
    margin-top: 20px; // Adjust the value as needed
  }

  &__text-wrapper {
    position: absolute;
    // top: 0;
    // left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &--text {
      font-family: $default-font-family !important;
      color: #475467 !important;
      font-size: 0.9rem !important;
      &#timeline {
        display: none !important;
      }
    }
    &--percentage {
      color: #101828 !important;
      font-size: 2.5rem !important;
      &#timelinepercentage {
        font-family: $Gilroy-light !important;
        color: #3b5269 !important;
        font-size: 0.6rem !important;
        @include sm {
          margin-top: 0vh !important;
          font-size: 0.5rem !important;
        }
      }
      @include sm {
        margin-top: 1vh !important;
        font-size: 2rem !important;
      }
    }
  }
}
