.css-eqpfi5-MuiAccordionSummary-content {
  align-items: center !important;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  opacity: 0 !important;
}
//remove padding from timeline
.css-6l1m71-MuiTimeline-root {
  padding: 0px !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px 16px 16px;
}

.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
  padding: 0px 16px;
}
.course-outline-wrapper::before{
  height: 0px !important;
}
.css-1aj41gs::before{
  height: 0px !important;
}

.course-outline-wrapper {
  border-radius: 30px !important;
  margin-top: 2vh !important;
  padding: 2vh 4vh !important;
  background-color: #ffffff !important;
  width: 100%;

  @include sm {
    margin-right: auto;
    border-radius: 30px !important;
    margin-top: 12vh !important;
    padding: 2vh 4vh !important;
    width: 82%;
  }
  @include md {
    width: 95%;
    margin-top: 13vh !important;
  }
  &__circle-icon {
    width: 4.5%;
    @include sm {
      width: 9.5%;
    }
    @include md {
      width: 7.5%;
    }
    &#transparentcircle {
      width: 4.5%;
      @include sm {
        width: 7% !important;
      }
      @include md {
        width: 6% !important;
      }
    }
  }
  &__circle-icon-list {
    width: 34px;
    @include sm {
      width: 19.5px;
    }
    @include md {
      width: 19px;
    }
    &#transparent-circle {
      width: 28px !important;
      margin-left: 2px !important;
      // padding-left: 2px !important;
      @include sm {
        width: 16px !important;
      }
      @include md {
        width: 16px !important;
      }
    }
  }
  &__title {
    margin-left: 1vh !important;
    font-family: $Gilroy-semi-bold !important;
    font-size: 1.1rem !important;
    color: $secondary-app-color !important;
    @include sm {
      font-size: 0.95rem !important;
    }
    @include md {
      font-size: 1.2rem !important;
    }
  }
  &__accordian {
    width: 100%;
    border: 1px solid #a9a9a9 !important;
    border-radius: 20px !important;
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
    box-shadow: none !important;
    
    &--summary {

      
     
      
    }


   
  }
  &__checklist-text {
    // font-family: $default-font-family !important;
    // font-size: 0.8rem !important;
    // color: $secondary-app-color !important;
    
  }

  //list
  &__list-item-text {
    font-family: $default-font-family !important;
    font-size: 0.8rem !important;
    color: $secondary-app-color !important;
    @include md {
      font-size: 6rem !important;
    }
  }
  &__list-item-duration {
    font-family: $Gilroy-light !important;
    font-size: 0.7rem !important;
    color: $secondary-app-color !important;
  }
}

//timeline content
.timeline-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &#course-outline {
    margin-top: 1vh;
  }
  &__text {
    font-family: $default-font-family !important;
    font-size: 0.95rem !important;
    color: $secondary-app-color !important;
    &#cursor {
      cursor: pointer;
    }
    @include sm {
      font-size: 0.8rem !important;
    }
    @include md {
      font-size: 3rem !important;
    }
  }
  &__duration {
    font-family: $Gilroy-light !important;
    font-size: 0.85rem !important;
    color: $secondary-app-color !important;
    @include sm {
      font-size: 0.7rem !important;
    }
    @include md {
      font-size: 0.75rem !important;
    }
  }
  &__link {
    cursor: pointer !important;
  }
}

//timeline
.course-outline-timeline {
  padding: 0 !important;
  flex: 0;
  &__item {
    &--connector {
      opacity: 0.5 !important;
      width: 1px !important;
    }
    &--content {
      padding: 0vh 0.8vh !important;
      
    }
  }
}

//course-view message
// .course-view-component-wrapper {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 100%;
//   padding: 6vh;
//   gap: 3rem;
//   @include sm {
//     width: 75% !important;
//     margin-top: 0;
//   }
//   @include md {
//     margin-top: 10vh;
//     width: 69% !important;
//     height: 72%;
//     padding: 5vh 4vh 5vh 9vh;
//   }
// }

//active lesson
.active-lesson {
  border: 1.2px solid $primary-color;
  border-radius: 50%;
}
